import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import classNames from 'classnames/bind';
import styles from './DispatchRouteMap.scss';
import GoogleMapReact from 'google-map-react';
import { BOOTSTRAP_URL_KEYS, DEFAULT_CENTER, DEFAULT_ZOOM, MAP_OPTIONS } from 'common/store/constants';
import GoogleMapContext from 'common/contexts/google-map-context';
import { StopEnum } from 'common/utils/api/models';
import last from 'lodash/last';
import { AssetTypeEnum } from 'common/constants';
import LastAssetTrackPointPin from 'common/components/maps/pins/LastAssetTrackPointPin/LastAssetTrackPointPin';
import MapRoute, { MapRouteThemeEnum } from 'common/components/maps/MapRoute/MapRoute';
import MapBound, { BoundPointT } from 'common/components/maps/MapBound/MapBound';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import { useDispatch, useSelector } from 'react-redux';
import { selectRoutingGeometryState } from 'common/store/routing-geometry/selectors';
import RouteSwitchDropdown, { RouteTypeEnum } from '../RouteSwitchDropdown/RouteSwitchDropdown';
import { useRouteGeometry } from 'common/utils/hooks/useRouteGeometry';
import { isNonNil } from 'common/utils';
import MapLoader from 'common/components/maps/MapLoader/MapLoader';
import { getAssetTrackHashByTransportOrderId } from 'common/store/asset-track/utils';
import { selectAssetTrackPoints, selectAssetTrackRequest } from 'common/store/asset-track/selectors';
import AssetDropOffLocationPin from 'common/components/maps/pins/AssetDropOffLocationPin/AssetDropOffLocationPin';
import AssetPickUpLocationPin from 'common/components/maps/pins/AssetPickUpLocationPin/AssetPickUpLocationPin';
import { fetchTracksByTransportOrder } from 'common/store/asset-track/actions';
import { findActualTour } from 'broker-admin/store/dispatch-details/utils/find-actual-tour';
import { findActualTransportOrders } from 'broker-admin/store/dispatch-details/utils/find-actual-transport-order';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import Supercluster from 'supercluster';
import { PointGeoJsonPropertiesT } from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/DispatchRouteMap/models';
import isNumber from 'lodash/isNumber';
import PointStackClusterPin from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/DispatchRouteMap/PointStackClusterPin/PointStackClusterPin';
import WaypointNumberPin from 'common/components/maps/pins/WaypointNumberPin/WaypointNumberPin';
import { MapPinThemeEnum } from 'common/components/maps/MapPin/MapPin';

const cx = classNames.bind(styles);

type PropsT = {
    dispatchDetails: DispatchDetailsT | null;
};

const FEATURES_LIMIT = 99999;
const DEFAULT_OFFSET = 0;

const SUPERCLUSTER_SETTINGS: Supercluster.Options<PointGeoJsonPropertiesT, any> = {
    radius: 100,
    maxZoom: 22,
};

const DispatchRouteMap: React.FC<PropsT> = React.memo((props) => {
    const { dispatchDetails } = props;

    const dispatch = useDispatch();

    const actualTour = findActualTour(dispatchDetails?.tours);

    const { carrierTransportOrder, trailerTransportOrder, truckTransportOrder } = findActualTransportOrders(
        actualTour?.transportationOrders,
    );
    const firstTruck = truckTransportOrder?.truck || null;
    const firstTrailer = trailerTransportOrder?.trailer || null;

    const truckTransportOrderId = truckTransportOrder?.id || null;
    const trailerTransportOrderId = trailerTransportOrder?.id || null;

    React.useEffect(() => {
        if (truckTransportOrderId) {
            dispatch(fetchTracksByTransportOrder(truckTransportOrderId));
        }
        if (truckTransportOrderId !== trailerTransportOrderId && trailerTransportOrderId) {
            dispatch(fetchTracksByTransportOrder(trailerTransportOrderId));
        }
    }, [truckTransportOrderId, trailerTransportOrderId]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        if (truckTransportOrderId) {
            dispatch(fetchTracksByTransportOrder(truckTransportOrderId));
        }
        if (truckTransportOrderId !== trailerTransportOrderId && trailerTransportOrderId) {
            dispatch(fetchTracksByTransportOrder(trailerTransportOrderId));
        }
    }, [truckTransportOrderId, trailerTransportOrderId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const trailerHash = getAssetTrackHashByTransportOrderId(truckTransportOrderId, firstTrailer?.id);
    const trailerTrack = useSelector(selectAssetTrackPoints(trailerHash));
    const trailerTrackRequest = useSelector(selectAssetTrackRequest(trailerHash));

    const truckHash = getAssetTrackHashByTransportOrderId(trailerTransportOrderId, firstTruck?.id);
    const truckTrack = useSelector(selectAssetTrackPoints(truckHash));
    const truckTrackRequest = useSelector(selectAssetTrackRequest(truckHash));

    const [routeType, setRouteType] = useState<RouteTypeEnum | null>(
        carrierTransportOrder ? RouteTypeEnum.carrierRoute : RouteTypeEnum.estimateCarrierRoute,
    );
    useEffect(() => {
        setRouteType(carrierTransportOrder ? RouteTypeEnum.carrierRoute : RouteTypeEnum.estimateCarrierRoute);
    }, [carrierTransportOrder]);

    const googleMapContext = React.useContext(GoogleMapContext);

    const routePolylineIds = useMemo((): Array<PolylineIdT> => {
        switch (routeType) {
            case RouteTypeEnum.carrierRoute: {
                return [
                    actualTour?.polylineId,
                    actualTour?.trailerToPickUpPolylineId,
                    actualTour?.truckToTrailerPolylineId,
                    actualTour?.dropTrailerPolylineId,
                    actualTour?.dropTruckPolylineId,
                ].filter(isNonNil);
            }
            case RouteTypeEnum.estimateCarrierRoute: {
                return [actualTour?.polylineId].filter(isNonNil);
            }
            case RouteTypeEnum.shipperRoute: {
                return [dispatchDetails?.order?.polylineId].filter(isNonNil);
            }
            default: {
                return [];
            }
        }
    }, [actualTour, routeType]);

    useRouteGeometry(routePolylineIds);

    const shipperRouteGeometryState = useSelector(selectRoutingGeometryState(dispatchDetails?.order?.polylineId));

    const estimateCarrierRouteGeometryState = useSelector(selectRoutingGeometryState(actualTour?.polylineId));

    const carrierRoutePayloadGeometryState = useSelector(selectRoutingGeometryState(actualTour?.polylineId));
    const carrierRouteTrailerToPickUpGeometryState = useSelector(
        selectRoutingGeometryState(actualTour?.trailerToPickUpPolylineId),
    );
    const carrierRouteTruckToTrailerGeometryState = useSelector(
        selectRoutingGeometryState(actualTour?.truckToTrailerPolylineId),
    );
    const carrierRouteDropTrailerGeometryState = useSelector(
        selectRoutingGeometryState(actualTour?.dropTrailerPolylineId),
    );
    const carrierRouteDropTruckGeometryState = useSelector(selectRoutingGeometryState(actualTour?.dropTruckPolylineId));

    const geometryStates = useMemo(() => {
        switch (routeType) {
            case RouteTypeEnum.carrierRoute: {
                return [
                    carrierRoutePayloadGeometryState,
                    carrierRouteTrailerToPickUpGeometryState,
                    carrierRouteTruckToTrailerGeometryState,
                    carrierRouteDropTrailerGeometryState,
                    carrierRouteDropTruckGeometryState,
                ];
            }
            case RouteTypeEnum.estimateCarrierRoute: {
                return [estimateCarrierRouteGeometryState];
            }
            case RouteTypeEnum.shipperRoute: {
                return [shipperRouteGeometryState];
            }
            default: {
                return [];
            }
        }
    }, [
        routeType,
        carrierRoutePayloadGeometryState,
        carrierRouteTrailerToPickUpGeometryState,
        carrierRouteTruckToTrailerGeometryState,
        carrierRouteDropTrailerGeometryState,
        carrierRouteDropTruckGeometryState,
        estimateCarrierRouteGeometryState,
        shipperRouteGeometryState,
    ]);

    const isLoadingPolylines = useMemo(() => {
        return geometryStates.some((geometryState) => {
            return geometryState?.requestStatus && geometryState?.requestStatus?.loading;
        });
    }, [geometryStates]);

    const boundMapPoints = React.useMemo((): Array<BoundPointT> => {
        return (
            actualTour?.waypoints?.map((waypoint): BoundPointT => {
                return [waypoint?.address?.latitude, waypoint?.address?.longitude];
            }) || []
        );
    }, [actualTour]);

    const apiIsLoaded: OnGoogleApiLoadedT = (api) => {
        setApi(api);

        const { map, maps } = api;

        googleMapContext.googleMaps?.set(maps, map, ['geometry']);
    };

    const lastTruckTrackPoint = last(truckTrack);
    const lastTrailerTrackPoint = last(trailerTrack);

    const isLoading = isLoadingPolylines || truckTrackRequest?.loading || trailerTrackRequest?.loading;

    const allGeoJSONs = React.useMemo(() => {
        const geoJSONs: Array<Supercluster.PointFeature<PointGeoJsonPropertiesT>> = [];

        actualTour?.waypoints?.forEach((waypoint) => {
            const longitude = waypoint?.address?.longitude;
            const latitude = waypoint?.address?.latitude;
            if (isNumber(latitude) && isNumber(longitude)) {
                const geoJson: Supercluster.PointFeature<PointGeoJsonPropertiesT> = {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [longitude, latitude],
                    },
                    properties: {
                        type: 'waypoint-point',
                        waypoint,
                    },
                };

                geoJSONs.push(geoJson);
            }
        });

        if (lastTrailerTrackPoint) {
            geoJSONs.push({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [lastTrailerTrackPoint.lng, lastTrailerTrackPoint.lat],
                },
                properties: {
                    type: 'trailer-last-point',
                    point: lastTrailerTrackPoint,
                },
            });
        }

        if (lastTruckTrackPoint) {
            geoJSONs.push({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [lastTruckTrackPoint.lng, lastTruckTrackPoint.lat],
                },
                properties: {
                    type: 'truck-last-point',
                    point: lastTruckTrackPoint,
                },
            });
        }

        return geoJSONs;
    }, [actualTour?.waypoints, lastTruckTrackPoint, lastTrailerTrackPoint]);

    const allGeoJSONsClusterator = React.useMemo(() => {
        const clusterator = new Supercluster<PointGeoJsonPropertiesT>(SUPERCLUSTER_SETTINGS);
        clusterator.load(allGeoJSONs || []);
        return clusterator;
    }, [allGeoJSONs]);

    const [config, setConfig] = React.useState<TODO>(null);

    const allGeoJSONsClusters = React.useMemo(() => {
        if (config) {
            const clusters = allGeoJSONsClusterator.getClusters(config.bounds, config.zoom);
            return clusters;
        }

        return [];
    }, [config, allGeoJSONsClusterator]);

    const [api, setApi] = React.useState<TODO>(null);

    const handleChange = () => {
        if (api) {
            const { zoom } = api.map;

            const bound = api.map.getBounds();

            const northEast = bound.getNorthEast();
            const southWest = bound.getSouthWest();

            setConfig({
                zoom,
                bounds: [southWest.lng(), southWest.lat(), northEast.lng(), northEast.lat()],
            });
        }
    };

    const renderSinglePointFeature = (properties: PointGeoJsonPropertiesT) => {
        switch (properties.type) {
            case 'trailer-last-point': {
                return (
                    <LastAssetTrackPointPin
                        key="trailer-last-point"
                        iconType={AssetTypeEnum.trailer}
                        lat={properties.point.lat}
                        lng={properties.point.lng}
                        timestamp={properties.point.timestamp}
                    />
                );
            }
            case 'truck-last-point': {
                return (
                    <LastAssetTrackPointPin
                        key="truck-last-point"
                        iconType={AssetTypeEnum.truck}
                        lat={properties.point.lat}
                        lng={properties.point.lng}
                        timestamp={properties.point.timestamp}
                    />
                );
            }
            case 'waypoint-point': {
                const { waypoint } = properties;

                switch (waypoint.type) {
                    case StopEnum.pickupTruck: {
                        if (routeType !== RouteTypeEnum.carrierRoute) {
                            return null;
                        }

                        return (
                            <AssetPickUpLocationPin
                                assetType={AssetTypeEnum.truck}
                                key="hookTruckStop"
                                className={cx('route-point-pin')}
                                lat={waypoint.address?.latitude}
                                lng={waypoint.address?.longitude}
                            />
                        );
                    }
                    case StopEnum.pickupTrailer: {
                        if (routeType !== RouteTypeEnum.carrierRoute) {
                            return null;
                        }

                        return (
                            <AssetPickUpLocationPin
                                assetType={AssetTypeEnum.trailer}
                                key="hookTrailerStop"
                                className={cx('route-point-pin')}
                                lat={waypoint.address?.latitude}
                                lng={waypoint.address?.longitude}
                            />
                        );
                    }
                    case StopEnum.pickupRoadTrain: {
                        if (routeType !== RouteTypeEnum.carrierRoute) {
                            return null;
                        }

                        return (
                            <AssetPickUpLocationPin
                                assetType={null}
                                key="link-pickup"
                                className={cx('route-point-pin')}
                                lat={waypoint.address?.latitude}
                                lng={waypoint.address?.longitude}
                            />
                        );
                    }
                    case StopEnum.driveThrough:
                    case StopEnum.pickupDeliveryShipment: {
                        let theme = MapPinThemeEnum.charcoal;
                        if (waypoint.type === StopEnum.driveThrough) {
                            theme = MapPinThemeEnum.gray;
                        }

                        const isVisited = !!waypoint?.driverLeftTimeStamp;
                        if (isVisited) {
                            theme = MapPinThemeEnum.brandDark;
                        }

                        return (
                            <WaypointNumberPin
                                key={`route-point-${waypoint.id}`}
                                className={cx('route-point-pin')}
                                theme={theme}
                                lng={waypoint?.address?.longitude}
                                lat={waypoint?.address?.latitude}
                            >
                                {(waypoint.index || 0) + 1}
                            </WaypointNumberPin>
                        );
                    }
                    case StopEnum.dropTrailer: {
                        if (routeType !== RouteTypeEnum.carrierRoute) {
                            return null;
                        }

                        return (
                            <AssetDropOffLocationPin
                                assetType={AssetTypeEnum.trailer}
                                key="dropTrailerStop"
                                className={cx('route-point-pin')}
                                lng={waypoint?.address?.longitude}
                                lat={waypoint?.address?.latitude}
                            />
                        );
                    }
                    case StopEnum.dropTruck: {
                        if (routeType !== RouteTypeEnum.carrierRoute) {
                            return null;
                        }

                        return (
                            <AssetDropOffLocationPin
                                assetType={AssetTypeEnum.truck}
                                key="dropTruckStop"
                                className={cx('route-point-pin')}
                                lng={waypoint?.address?.longitude}
                                lat={waypoint?.address?.latitude}
                            />
                        );
                    }
                    case StopEnum.dropRoadTrain: {
                        if (routeType !== RouteTypeEnum.carrierRoute) {
                            return null;
                        }

                        return (
                            <AssetDropOffLocationPin
                                assetType={null}
                                key="link-dropoff"
                                className={cx('route-point-pin')}
                                lng={waypoint?.address?.longitude}
                                lat={waypoint?.address?.latitude}
                            />
                        );
                    }
                    default: {
                        return null;
                    }
                }
            }
            default: {
                return null;
            }
        }
    };

    const handleClusterClick = React.useCallback(
        (clusterId: number) => {
            const { map, maps } = api;

            const clusterFeatures = allGeoJSONsClusterator.getLeaves(clusterId, FEATURES_LIMIT, DEFAULT_OFFSET);

            const bounds = new maps.LatLngBounds();

            clusterFeatures.forEach((feature: Supercluster.PointFeature<{}>) => {
                const point = new maps.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);

                bounds.extend(point);
            });

            map.fitBounds(bounds);
        },
        [api, allGeoJSONsClusterator],
    );

    return (
        <>
            {isLoading && <MapLoader className={cx('loader')} />}
            <GoogleMapReact
                defaultCenter={DEFAULT_CENTER}
                defaultZoom={DEFAULT_ZOOM}
                bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                options={MAP_OPTIONS}
                onGoogleApiLoaded={apiIsLoaded}
                onDragEnd={handleChange}
                onZoomAnimationEnd={handleChange}
                yesIWantToUseGoogleMapApiInternals
            >
                {allGeoJSONsClusters.map((cluster) => {
                    if ('cluster' in cluster.properties) {
                        const pointsFeatures = allGeoJSONsClusterator.getLeaves(
                            cluster.properties.cluster_id,
                            FEATURES_LIMIT,
                            DEFAULT_OFFSET,
                        );

                        const clusterProperties = pointsFeatures.map((pointFeatures) => {
                            return pointFeatures.properties;
                        });

                        return (
                            <PointStackClusterPin
                                key={`cluster-${cluster.properties.cluster_id}`}
                                clusterId={cluster.properties.cluster_id}
                                lng={cluster.geometry.coordinates[0]}
                                lat={cluster.geometry.coordinates[1]}
                                clusterProperties={clusterProperties}
                                onClusterClick={handleClusterClick}
                                withoutArrow={config.zoom < 20}
                            />
                        );
                    }
                    return renderSinglePointFeature(cluster.properties as PointGeoJsonPropertiesT);
                })}
            </GoogleMapReact>
            {geometryStates?.map((geometryState, index) => (
                <MapRoute
                    key={`geometry-${index}`}
                    map={googleMapContext.googleMaps?.map}
                    maps={googleMapContext.googleMaps?.maps}
                    geometryLibrary={googleMapContext.googleMaps?.libraries?.geometry}
                    polylines={geometryState.data}
                    theme={MapRouteThemeEnum.trackFuture}
                />
            ))}
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                route={trailerTrack}
                theme={MapRouteThemeEnum.trackPast}
            />
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                route={truckTrack}
                theme={MapRouteThemeEnum.trackPast}
            />
            <MapBound
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                allMapPoints={boundMapPoints}
            />
            {!!routePolylineIds?.length && (
                <RouteSwitchDropdown
                    className={cx('route-type-switcher')}
                    value={routeType}
                    onChange={setRouteType}
                    isDisabledEstimateCarrierRoute={!actualTour}
                    isDisabledShipperRoute={!dispatchDetails}
                    isDisabledCarrierRoute={!carrierTransportOrder}
                />
            )}
        </>
    );
});

export default DispatchRouteMap;
