import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './AssignTriggerLabel.scss';
import { CurrencyEnum, StyleGuideColorsEnum } from 'common/constants';
import Money from 'common/components/Money/Money';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import CloseIcon from 'common/icons/CloseIcon';
import DetailsIcon from 'common/icons/DetailsIcon';
import ClickInterceptorLabel from 'common/components/ClickInterceptorLabel/ClickInterceptorLabel';
import isNumber from 'lodash/isNumber';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

type PropsT = {
    model?: string;
    plateNumber?: string;
    rate?: number | null;
    resetTooltipNode?: React.ReactNode;
    onReset: () => void;
    onOpenDetails: () => void;
};

const AssignTriggerLabel: React.FC<PropsT> = React.memo((props) => {
    const { model, plateNumber, rate, onReset, onOpenDetails, resetTooltipNode } = props;

    const { t } = useTranslation();

    return (
        <div className={cx('option')}>
            <div className={cx('model-and-plate-number')}>{[plateNumber, model].filter(Boolean).join(' / ')}</div>
            <div className={cx('spacer')} />
            {isNumber(rate) && (
                <div className={cx('price')}>
                    <Money amount={rate} currency={CurrencyEnum.EUR} />
                </div>
            )}
            <ClickInterceptorLabel className={cx('reset')}>
                <Tooltip
                    className={cx('tooltip')}
                    position={TooltipPositionEnum.centerLeft}
                    theme={TooltipThemeEnum.black}
                    tooltipNode={
                        resetTooltipNode ? (
                            <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                                {resetTooltipNode}
                            </TooltipContent>
                        ) : null
                    }
                >
                    {() => (
                        <TransparentTrigger
                            spaces="xxs"
                            onClick={onReset}
                            leftIcon={<CloseIcon fillColor={StyleGuideColorsEnum.gray} />}
                            reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                        />
                    )}
                </Tooltip>
            </ClickInterceptorLabel>
            <ClickInterceptorLabel className={cx('details')}>
                <Tooltip
                    className={cx('tooltip')}
                    position={TooltipPositionEnum.centerLeft}
                    theme={TooltipThemeEnum.black}
                    tooltipNode={
                        <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                            {t('common:tooltips.open-details')}
                        </TooltipContent>
                    }
                >
                    {() => (
                        <TransparentTrigger
                            spaces="xxs"
                            onClick={onOpenDetails}
                            leftIcon={<DetailsIcon strokeColor={StyleGuideColorsEnum.gray} />}
                            reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                        />
                    )}
                </Tooltip>
            </ClickInterceptorLabel>
        </div>
    );
});

export default AssignTriggerLabel;
