import isNumber from 'lodash/isNumber';

export const getValuesDiff = (valueA: number | null | undefined, valueB: number | null | undefined): number | null => {
    if (!isNumber(valueA) || !isNumber(valueB)) {
        return null;
    }

    return valueA - valueB;
};

export const getDiffPercent = (diff: number | null | undefined, base: number | null | undefined): null | number => {
    if (!isNumber(diff) || !isNumber(base)) {
        return null;
    }

    return Math.abs(Math.round((base ? diff / base : 0) * 100));
};
