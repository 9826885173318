import * as React from 'react';
import TruckIcon, { getTruckIconProps } from 'common/icons/TruckIcon';
import MapTooltip from 'common/components/maps/MapTooltip/MapTooltip';
import TrailerIcon, { getTrailerIconProps } from 'common/icons/TrailerIcon';
import AssetLinkIcon, { getAssetLinkIconProps } from 'common/icons/AssetLinkIcon';
import { MapPinThemeEnum } from 'common/components/maps/MapPin/MapPin';

type PropsT = {
    lat: number;
    lng: number;
    className?: string;
    isTrailer?: boolean;
    isTruck?: boolean;
    isLink?: boolean;
};

const CurrentAssetPositionPin: React.FC<PropsT> = React.memo((props) => {
    const { lat, lng, className, isTrailer, isTruck, isLink } = props;

    return (
        <MapTooltip hasPaddings contentClassName={className} lat={lat} lng={lng} theme={MapPinThemeEnum.brandDark}>
            {() => (
                <>
                    {isTruck && <TruckIcon {...getTruckIconProps('assignment-normal')} />}
                    {isTrailer && <TrailerIcon {...getTrailerIconProps('assignment-normal')} />}
                    {isLink && <AssetLinkIcon {...getAssetLinkIconProps('assignment-normal')} />}
                </>
            )}
        </MapTooltip>
    );
});

export default CurrentAssetPositionPin;
