import React from 'react';
import classNames from 'classnames/bind';

import styles from './PriceSettingsEditRuleSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import scrollIntoView from 'scroll-into-view';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useFormik } from 'formik';
import { FieldsEnum, FormValuesT, RepeatTypeEnum } from './constants';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import validateForm from './validate-form';
import getInitialValues from './get-initial-values';
import prepareApiRule from './prepare-api-rule';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import ColoredStatusLabel from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import CheckboxOptionsList from 'common/components/CheckboxOptionsList/CheckboxOptionsList';
import { CountryCodeT } from 'common/store/countries-dict/models';
import CountyFlagLabel from 'common/components/CountyFlagLabel/CountyFlagLabel';
import { useDispatch, useSelector } from 'react-redux';
import { selectCountriesAllCodes, selectCountriesByCode } from 'common/store/countries-dict/selectors';
import DatePicker, { DatePickerOverlayPositionEnum } from 'design-system/components/date-pickers/DatePicker/DatePicker';
import { getMoneyDiffMask, getPercentDiffMask } from 'common/utils/input-masks';
import DropdownMultipleInput from 'design-system/components/dropdowns/DropdownMultipleInput/DropdownMultipleInput';
import ControlsCell from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/RulesTable/cell-renderers/ControlsCell/ControlsCell';
import {
    CURRENCY_SYMBOLS,
    DaysEnum,
    RuleImpactStrategyEnum,
    StyleGuideColorsEnum,
    WEEK_DAYS_ORDER,
} from 'common/constants';
import {
    GroupOptionT,
    PriceComponentOptionT,
    RepeatStrategyOptionT,
} from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/models';
import { RuleImpactStrategyT } from 'common/utils/api/models';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import {
    selectCreateRuleRequest,
    selectFetchRuleDetailsRequest,
    selectRuleDetails,
    selectUpdateRulesRequest,
} from 'broker-admin/store/price-settings/rules/selectors';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import { logWarning } from 'common/utils/logger';
import ShipperDropdown from 'broker-admin/components/dropdowns/ShipperSuggest/ShipperDropdown';
import { PriceComponentTypeEnum, RepeatStrategyTypeEnum } from 'broker-admin/utils/api/broker-tranziit/models';
import TrailerTypeMultipleDropdown from 'broker-admin/components/dropdowns/TrailerTypeMultipleDropdown/TrailerTypeMultipleDropdown';
import { selectTrailerDictTypeIds } from 'common/store/trailers-dict/selectors';
import values from 'lodash/values';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';
import {
    activateRules,
    createRule,
    deactivateRules,
    deleteRules,
    fetchRuleDetails,
    updateRules,
} from 'broker-admin/store/price-settings/rules/actions';
import { SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { BrokerSidebarDataT } from 'broker-admin/layouts/SideBars/models';
import { PriceSettingsEditRuleSidebarDataT } from 'broker-admin/layouts/SideBars/PriceSettingsEditRuleSidebarContent/models';
import useCloseSidebarAfterRequest from 'common/utils/hooks/useCloseSidebarAfterRequest';
import { getGroupOptions, getPriceComponentsOptions } from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/utils';
import {
    PriceSettingsRuleGroupEnum,
    PriceComponentTypeT,
    REPEAT_STRATEGY_T_MAP,
    RepeatStrategyTypeT,
} from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/constants';
import noop from 'lodash/noop';
import { isNonNil } from 'common/utils';

const cx = classNames.bind(styles);

type RepeatTypeOptionT = {
    label: string;
    value: RepeatTypeEnum;
};

type StrategyOptionT = {
    label: string;
    value: RuleImpactStrategyEnum;
};

type DayOptionT = {
    label: string;
    value: DaysEnum;
};

const getMaskByType = (type: RuleImpactStrategyEnum) => {
    switch (type) {
        case RuleImpactStrategyEnum.percent:
            return getPercentDiffMask();
        case RuleImpactStrategyEnum.exactValue:
            return getMoneyDiffMask();
        default:
            return null;
    }
};

const UNIT_BY_TYPE: Record<RuleImpactStrategyT, string> = {
    [RuleImpactStrategyEnum.percent]: '%',
    [RuleImpactStrategyEnum.exactValue]: CURRENCY_SYMBOLS.EUR,
};

const ALL_FIELDS = values(FieldsEnum);
const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(ALL_FIELDS);

type PropsT = SidebarContentPropsT<PriceSettingsEditRuleSidebarDataT, BrokerSidebarDataT>;

const PriceSettingsEditRuleSidebarContent: React.FC<PropsT> = (props) => {
    const { onGoBack, onClose, setNeedCloseConfirmation, data } = props;

    const editableRuleId = 'editableRuleId' in data ? data.editableRuleId : null;
    const isNewRule = 'isNewRule' in data ? data.isNewRule : false;

    const createRuleRequest = useSelector(selectCreateRuleRequest);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const ref = React.createRef<HTMLDivElement>();

    React.useEffect(() => {
        if (!ref.current) {
            return;
        }

        scrollIntoView(ref.current, {
            time: 300,
        });
    }, [data]);

    const validate = React.useCallback((values: FormValuesT) => validateForm(t, values), [t]);

    const trailerDictTypeIds = useSelector(selectTrailerDictTypeIds);
    const fetchRuleDetailsRequest = useSelector(selectFetchRuleDetailsRequest);
    const updateRulesRequest = useSelector(selectUpdateRulesRequest);
    const editableRuleDetails = useSelector(selectRuleDetails(editableRuleId || null));

    React.useEffect(() => {
        if (editableRuleId) {
            dispatch(fetchRuleDetails(editableRuleId));
        }
    }, [editableRuleId]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues(editableRuleDetails, trailerDictTypeIds);
        const errors = validateForm(t, values);

        return [values, errors];
    }, [editableRuleDetails, trailerDictTypeIds]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const ruleDraft = prepareApiRule(values);
            if (!ruleDraft) {
                logWarning('empty ruleDraft, not valid values');
                return;
            }

            if (editableRuleId) {
                dispatch(updateRules(editableRuleId, ruleDraft));
            } else {
                dispatch(createRule(ruleDraft));
            }

            formikHelpers.setTouched({});
        },
    });

    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);
    React.useEffect(() => {
        setNeedCloseConfirmation(hasAnyFieldValueChanges);
    }, [hasAnyFieldValueChanges]);

    useCloseSidebarAfterRequest(createRuleRequest, onGoBack || onClose);

    const isDisabledSubmit = (!isNewRule && !hasAnyFieldValueChanges) || createRuleRequest.loading;

    const groupOptions = getGroupOptions(t);

    const renderOption = (option: GroupOptionT | null | undefined) => (option ? <span>{option.label}</span> : null);

    const getOptionValue = (option: GroupOptionT) => option?.value;

    const allCountryCodes = useSelector(selectCountriesAllCodes);
    const countryByCode = useSelector(selectCountriesByCode);

    const renderCountryOption = React.useCallback(
        (countryCode: CountryCodeT) => {
            return <CountyFlagLabel isThin country={countryByCode[countryCode]} />;
        },
        [countryByCode],
    );

    const getToOptionTestSelector = React.useCallback((countryCode: CountryCodeT) => {
        return `to_country_option_${countryCode}`;
    }, []);

    const getFromOptionTestSelector = React.useCallback((countryCode: CountryCodeT) => {
        return `from_country_option_${countryCode}`;
    }, []);

    const getCountryOptionValue = React.useCallback((option: CountryCodeT) => option, []);

    const impactPriceComponentValue = formik.values[FieldsEnum.impactPriceComponent];
    const isAvailableExact = impactPriceComponentValue !== PriceComponentTypeEnum.fuel;
    const strategyImpactOptions: StrategyOptionT[] = [
        {
            label: t(`price-settings.rules.impact-strategy.${RuleImpactStrategyEnum.percent}`),
            value: RuleImpactStrategyEnum.percent,
        },
        isAvailableExact
            ? {
                  label: t(`price-settings.rules.impact-strategy.${RuleImpactStrategyEnum.exactValue}`),
                  value: RuleImpactStrategyEnum.exactValue,
              }
            : null,
    ].filter(isNonNil);

    React.useEffect(() => {
        const impactStrategyValue = formik.values[FieldsEnum.impactStrategy];
        const isSelectedAvailableOption = strategyImpactOptions.some((option) => {
            return option.value === impactStrategyValue;
        });
        if (!isSelectedAvailableOption) {
            formik.setValues((values) => {
                return {
                    ...values,
                    [FieldsEnum.impactStrategy]: strategyImpactOptions?.[0]?.value,
                    [FieldsEnum.impactValue]: '+',
                };
            });
        }
    }, [impactPriceComponentValue]);

    const priceComponentsOptions = getPriceComponentsOptions(t);

    const repeatTypeOptions: RepeatTypeOptionT[] = [
        {
            label: t(`price-settings.rules.repeat-types.${RepeatTypeEnum.days}`),
            value: RepeatTypeEnum.days,
        },
        {
            label: t(`price-settings.rules.repeat-types.${RepeatTypeEnum.dates}`),
            value: RepeatTypeEnum.dates,
        },
    ];

    let repeatStrategyOptions: RepeatStrategyOptionT[] = [];

    if (formik.values[FieldsEnum.repeatType] === RepeatTypeEnum.days) {
        repeatStrategyOptions = [
            {
                label: t(REPEAT_STRATEGY_T_MAP[RepeatStrategyTypeEnum.none]),
                value: RepeatStrategyTypeEnum.none,
            },
            {
                label: t(REPEAT_STRATEGY_T_MAP[RepeatStrategyTypeEnum.weekly]),
                value: RepeatStrategyTypeEnum.weekly,
            },
        ];
    }

    if (formik.values[FieldsEnum.repeatType] === RepeatTypeEnum.dates) {
        repeatStrategyOptions = [
            {
                label: t(REPEAT_STRATEGY_T_MAP[RepeatStrategyTypeEnum.none]),
                value: RepeatStrategyTypeEnum.none,
            },
            {
                label: t(REPEAT_STRATEGY_T_MAP[RepeatStrategyTypeEnum.monthly]),
                value: RepeatStrategyTypeEnum.monthly,
            },
            {
                label: t(REPEAT_STRATEGY_T_MAP[RepeatStrategyTypeEnum.yearly]),
                value: RepeatStrategyTypeEnum.yearly,
            },
        ];
    }

    const dayOptions: DayOptionT[] = WEEK_DAYS_ORDER.map((day) => ({
        label: t(`common:week-days.${day}`),
        value: day,
    }));

    const handleSelectImpactStrategy = (strategy: RuleImpactStrategyEnum) => {
        formik.setFieldValue(FieldsEnum.impactStrategy, strategy);
    };

    const handleSelectRepeatType = (repeatType: RepeatTypeEnum) => {
        formik.setFieldValue(FieldsEnum.repeatType, repeatType);
        formik.setFieldValue(FieldsEnum.repeatStrategy, RepeatStrategyTypeEnum.none);
    };

    React.useEffect(() => {
        const repeatType = formik.values[FieldsEnum.repeatType];

        if (repeatType === RepeatTypeEnum.dates) {
            formik.setFieldValue(FieldsEnum.days, []);
        }

        if (repeatType === RepeatTypeEnum.days) {
            formik.setFieldValue(FieldsEnum.dates, {
                from: null,
                to: null,
            });
        }
    }, [formik.values[FieldsEnum.repeatType]]);

    React.useEffect(() => {
        const fromCountryCodes = formik.values[FieldsEnum.fromCountryCodes];

        if (fromCountryCodes?.length !== 1) {
            formik.setFieldValue(FieldsEnum.fromCountryZipCodes, '');
        }
    }, [formik.values[FieldsEnum.fromCountryCodes]]);

    React.useEffect(() => {
        const toCountryCodes = formik.values[FieldsEnum.toCountryCodes];

        if (toCountryCodes?.length !== 1) {
            formik.setFieldValue(FieldsEnum.toCountryZipCodes, '');
        }
    }, [formik.values[FieldsEnum.toCountryCodes]]);

    const isShowLoader = fetchRuleDetailsRequest.loading || updateRulesRequest.loading;

    const hasChangeHighlight =
        !isNewRule && formik.values[FieldsEnum.id] === editableRuleDetails?.id && !isShowLoader && formik.dirty;

    const headerTitle = isNewRule ? t('price-settings.rules.form.new-rule') : editableRuleDetails?.name;

    const handleActiveRule = (ruleId: RuleIdT) => {
        dispatch(activateRules([ruleId]));
    };

    const handleDeactiveRule = (ruleId: RuleIdT) => {
        dispatch(deactivateRules([ruleId]));
    };

    const handleDeleteRule = (ruleId: RuleIdT) => {
        dispatch(deleteRules([ruleId]));

        if (onClose) {
            onClose();
        }
    };

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            {isShowLoader && <LoaderOverlay />}
            <HeaderSideBarLayout>
                <HeaderSideBarContent
                    title={headerTitle}
                    rightNode={
                        <>
                            {!isNewRule && hasAnyFieldValueChanges && hasChangeHighlight && (
                                <ColoredStatusLabel
                                    className={cx('status')}
                                    label={t('common:status.not-saved')}
                                    color={StyleGuideColorsEnum.orange}
                                />
                            )}
                            {!isNewRule && (
                                <ControlsCell
                                    id={editableRuleId}
                                    isActive={!!editableRuleDetails?.active}
                                    className={cx('controls')}
                                    onActiveRule={handleActiveRule}
                                    onDeactiveRule={handleDeactiveRule}
                                    onDeleteRule={handleDeleteRule}
                                />
                            )}
                        </>
                    }
                    testSelector="edit-rule"
                    onClose={onClose}
                />
            </HeaderSideBarLayout>
            <SideBarLayout testSelector="edit-rule">
                <div className={cx('section')} ref={ref}>
                    <div className={cx('section__title')}>{t('price-settings.rules.form.sections.name')}</div>
                    <div className={cx('section__block')}>
                        <FieldGroup>
                            <FormikField
                                className={cx('field--name')}
                                name={FieldsEnum.name}
                                error={formik.errors[FieldsEnum.name]}
                                meta={formik.getFieldMeta(FieldsEnum.name)}
                                label={t('price-settings.rules.form.fields.name.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.name}
                                        value={formik.values[FieldsEnum.name]}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        hasChanges={!isNewRule && props.hasChanges}
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field--group')}
                                name={FieldsEnum.group}
                                error={formik.errors[FieldsEnum.group]}
                                meta={formik.getFieldMeta(FieldsEnum.group)}
                                label={t('price-settings.rules.form.fields.group.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <DropdownInput<GroupOptionT, PriceSettingsRuleGroupEnum | null>
                                        selectedValue={formik.values[FieldsEnum.group]}
                                        placeholder={t('price-settings.rules.form.fields.group.placeholder')}
                                        options={groupOptions}
                                        onSelect={props.onChange}
                                        renderOption={renderOption}
                                        getOptionValue={getOptionValue}
                                        overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                        hasWarning={props.hasWarning}
                                        hasError={props.hasError}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasChanges={!isNewRule && props.hasChanges}
                                        testSelector="group"
                                    />
                                )}
                            </FormikField>
                        </FieldGroup>
                        <FormikField
                            name={FieldsEnum.shipperId}
                            error={formik.errors[FieldsEnum.shipperId]}
                            meta={formik.getFieldMeta(FieldsEnum.shipperId)}
                            label={t('price-settings.rules.form.fields.shipper.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <ShipperDropdown
                                    hasAllOption
                                    value={formik.values[FieldsEnum.shipperId]}
                                    initialValue={initialValues[FieldsEnum.shipperId]}
                                    initialLabel=""
                                    hasClearControl
                                    onSelect={props.onChange}
                                    hasWarning={props.hasWarning}
                                    hasError={props.hasError}
                                    overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                    placeholder={t('price-settings.rules.form.fields.shipper.placeholder')}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                />
                            )}
                        </FormikField>
                        <FormikField
                            name={FieldsEnum.trailerTypeIds}
                            error={formik.errors[FieldsEnum.trailerTypeIds]}
                            meta={formik.getFieldMeta(FieldsEnum.trailerTypeIds)}
                            label={t('price-settings.rules.form.fields.trailer-types.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <TrailerTypeMultipleDropdown
                                    values={formik.values[FieldsEnum.trailerTypeIds]}
                                    onSelect={props.onChange}
                                    hasWarning={props.hasWarning}
                                    hasError={props.hasError}
                                    overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                    placeholder={t('price-settings.rules.form.fields.trailer-types.placeholder')}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                />
                            )}
                        </FormikField>
                    </div>
                </div>
                <div className={cx('section')}>
                    <div className={cx('section__title')}>{t('price-settings.rules.form.sections.countries')}</div>
                    <div className={cx('section__block')}>
                        <FieldGroup>
                            <FormikField
                                className={cx('field--countries')}
                                name={FieldsEnum.fromCountryCodes}
                                error={formik.errors[FieldsEnum.fromCountryCodes]}
                                meta={formik.getFieldMeta(FieldsEnum.fromCountryCodes)}
                                label={t('price-settings.rules.form.fields.from-country-codes.label', {
                                    count: formik.values[FieldsEnum.fromCountryCodes].length,
                                })}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <CheckboxOptionsList<CountryCodeT, CountryCodeT>
                                        isShowSelectAllTrigger
                                        selectedValues={formik.values[FieldsEnum.fromCountryCodes]}
                                        hasWarning={props.hasWarning}
                                        hasError={props.hasError}
                                        options={allCountryCodes}
                                        onSelect={props.onChange}
                                        renderOption={renderCountryOption}
                                        getOptionTestSelector={getFromOptionTestSelector}
                                        getOptionValue={getCountryOptionValue}
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field--countries')}
                                name={FieldsEnum.toCountryCodes}
                                error={formik.errors[FieldsEnum.toCountryCodes]}
                                meta={formik.getFieldMeta(FieldsEnum.toCountryCodes)}
                                label={t('price-settings.rules.form.fields.to-country-codes.label', {
                                    count: formik.values[FieldsEnum.toCountryCodes].length,
                                })}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <CheckboxOptionsList<CountryCodeT, CountryCodeT>
                                        isShowSelectAllTrigger
                                        selectedValues={formik.values[FieldsEnum.toCountryCodes]}
                                        hasWarning={props.hasWarning}
                                        hasError={props.hasError}
                                        options={allCountryCodes}
                                        onSelect={props.onChange}
                                        renderOption={renderCountryOption}
                                        getOptionTestSelector={getToOptionTestSelector}
                                        getOptionValue={getCountryOptionValue}
                                    />
                                )}
                            </FormikField>
                        </FieldGroup>
                        <FieldGroup>
                            <FormikField
                                className={cx('field--zips')}
                                name={FieldsEnum.fromCountryZipCodes}
                                error={formik.errors[FieldsEnum.fromCountryZipCodes]}
                                meta={formik.getFieldMeta(FieldsEnum.fromCountryZipCodes)}
                                label={t('price-settings.rules.form.fields.zips.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.fromCountryZipCodes}
                                        value={formik.values[FieldsEnum.fromCountryZipCodes]}
                                        placeholder={t('price-settings.rules.form.fields.zips.placeholder')}
                                        isDisabled={formik.values[FieldsEnum.fromCountryCodes].length !== 1}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        hasChanges={!isNewRule && props.hasChanges}
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field--zips')}
                                name={FieldsEnum.toCountryZipCodes}
                                error={formik.errors[FieldsEnum.toCountryZipCodes]}
                                meta={formik.getFieldMeta(FieldsEnum.toCountryZipCodes)}
                                label={t('price-settings.rules.form.fields.zips.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.toCountryZipCodes}
                                        value={formik.values[FieldsEnum.toCountryZipCodes]}
                                        placeholder={t('price-settings.rules.form.fields.zips.placeholder')}
                                        isDisabled={formik.values[FieldsEnum.toCountryCodes].length !== 1}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        hasChanges={!isNewRule && props.hasChanges}
                                    />
                                )}
                            </FormikField>
                        </FieldGroup>
                    </div>
                </div>
                <div className={cx('section')}>
                    <div className={cx('section__title')}>{t('price-settings.rules.form.sections.schedule')}</div>
                    <div className={cx('section__block')}>
                        <FieldGroup>
                            <FieldGroup className={cx('field-group', 'field-group--schedule')} isFullWidth>
                                {formik.values[FieldsEnum.repeatType] === RepeatTypeEnum.days && (
                                    <FormikField
                                        label={t('price-settings.rules.form.fields.repeat.label')}
                                        name={FieldsEnum.days}
                                        error={formik.errors[FieldsEnum.days]}
                                        meta={formik.getFieldMeta(FieldsEnum.days)}
                                        setFieldValue={formik.setFieldValue}
                                        setFieldTouched={formik.setFieldTouched}
                                    >
                                        {(props) => (
                                            <DropdownMultipleInput<DayOptionT, DaysEnum>
                                                selectedValues={formik.values[FieldsEnum.days]}
                                                options={dayOptions}
                                                onSelect={props.onChange}
                                                placeholder={t('price-settings.rules.form.fields.repeat.placeholder')}
                                                renderTriggerContent={(selectedDays, placeholder) => {
                                                    if (!selectedDays.length) {
                                                        return <span>{placeholder}</span>;
                                                    }

                                                    const label = selectedDays
                                                        .map((day) => t(`common:week-short-days.${day.value}`))
                                                        .join(', ');

                                                    return <span>{label}</span>;
                                                }}
                                                renderOption={(option) => (option ? <span>{option.label}</span> : null)}
                                                hasWarning={props.hasWarning}
                                                hasError={props.hasError}
                                                triggerClassName={cx('combined-field--days')}
                                                getOptionValue={(option) => option.value}
                                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                                onBlur={props.onBlur}
                                                onFocus={props.onFocus}
                                                hasChanges={!isNewRule && props.hasChanges}
                                                testSelector="repeat-weekdays"
                                            />
                                        )}
                                    </FormikField>
                                )}
                                {formik.values[FieldsEnum.repeatType] === RepeatTypeEnum.dates && (
                                    <FormikField
                                        name={FieldsEnum.dates}
                                        error={formik.errors[FieldsEnum.dates]}
                                        meta={formik.getFieldMeta(FieldsEnum.dates)}
                                        label={t('price-settings.rules.form.fields.repeat.label')}
                                        setFieldValue={formik.setFieldValue}
                                        setFieldTouched={formik.setFieldTouched}
                                    >
                                        {(props) => (
                                            <DatePicker
                                                isRange
                                                initialValue={initialValues[FieldsEnum.dates]}
                                                value={formik.values[FieldsEnum.dates]}
                                                placeholder={t('price-settings.rules.form.fields.repeat.placeholder')}
                                                onChange={props.onChange}
                                                overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                                                hasError={props.hasError}
                                                hasWarning={props.hasWarning}
                                                onBlur={props.onBlur}
                                                onFocus={props.onFocus}
                                                hasChanges={!isNewRule && props.hasChanges}
                                                inputClassName={cx('combined-field--dates')}
                                                testSelectorPrefix="repeat-dates"
                                                hasClearControl
                                            />
                                        )}
                                    </FormikField>
                                )}
                                <FormikField
                                    name={FieldsEnum.repeatType}
                                    error={formik.errors[FieldsEnum.repeatType]}
                                    meta={formik.getFieldMeta(FieldsEnum.repeatType)}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <DropdownInput<RepeatTypeOptionT, RepeatTypeEnum>
                                            selectedValue={formik.values[FieldsEnum.repeatType]}
                                            options={repeatTypeOptions}
                                            onSelect={handleSelectRepeatType}
                                            renderOption={(option) => (option ? <span>{option.label}</span> : null)}
                                            triggerClassName={cx('combined-field--repeat-type')}
                                            getOptionValue={(option) => option.value}
                                            overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasChanges={!isNewRule && props.hasChanges}
                                            testSelector="repeat-type"
                                        />
                                    )}
                                </FormikField>
                            </FieldGroup>
                            <FormikField
                                className={cx('field--repeat-strategy')}
                                label={t('price-settings.rules.form.fields.repeat-strategy.label')}
                                name={FieldsEnum.repeatStrategy}
                                error={formik.errors[FieldsEnum.repeatStrategy]}
                                meta={formik.getFieldMeta(FieldsEnum.repeatStrategy)}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <DropdownInput<RepeatStrategyOptionT, RepeatStrategyTypeT>
                                        selectedValue={formik.values[FieldsEnum.repeatStrategy]}
                                        options={repeatStrategyOptions}
                                        onSelect={props.onChange}
                                        placeholder={t('price-settings.rules.form.fields.repeat-strategy.placeholder')}
                                        renderOption={(option) => (option ? <span>{option.label}</span> : null)}
                                        getOptionValue={(option) => option.value}
                                        overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasChanges={!isNewRule && props.hasChanges}
                                        testSelector="repeat-strategy"
                                    />
                                )}
                            </FormikField>
                        </FieldGroup>
                        <FieldGroup>
                            <FormikField
                                className={cx('field--valid-date')}
                                name={FieldsEnum.validFrom}
                                error={formik.errors[FieldsEnum.validFrom]}
                                meta={formik.getFieldMeta(FieldsEnum.validFrom)}
                                label={t('price-settings.rules.form.fields.valid-from.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <DatePicker
                                        value={formik.values[FieldsEnum.validFrom]}
                                        initialValue={initialValues[FieldsEnum.validFrom]}
                                        placeholder={t('price-settings.rules.form.fields.valid-from.placeholder')}
                                        onChange={props.onChange}
                                        overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasChanges={!isNewRule && props.hasChanges}
                                        testSelectorPrefix="valid-from"
                                        hasClearControl
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field--valid-date')}
                                name={FieldsEnum.validTill}
                                error={formik.errors[FieldsEnum.validTill]}
                                meta={formik.getFieldMeta(FieldsEnum.validTill)}
                                label={t('price-settings.rules.form.fields.valid-till.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <DatePicker
                                        value={formik.values[FieldsEnum.validTill]}
                                        initialValue={initialValues[FieldsEnum.validTill]}
                                        placeholder={t('price-settings.rules.form.fields.valid-till.placeholder')}
                                        onChange={props.onChange}
                                        overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasChanges={!isNewRule && props.hasChanges}
                                        testSelectorPrefix="valid-till"
                                        hasClearControl
                                    />
                                )}
                            </FormikField>
                        </FieldGroup>
                    </div>
                </div>
                <div className={cx('section')}>
                    <div className={cx('section__title')}>{t('price-settings.rules.form.sections.rule')}</div>
                    <div className={cx('section__block')}>
                        <FieldGroup>
                            <FormikField
                                className={cx('field--price-component')}
                                name={FieldsEnum.impactPriceComponent}
                                error={formik.errors[FieldsEnum.impactPriceComponent]}
                                meta={formik.getFieldMeta(FieldsEnum.impactPriceComponent)}
                                label={t('price-settings.rules.form.fields.impact-price-components.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => {
                                    const value = formik.values[FieldsEnum.impactPriceComponent];

                                    return (
                                        <DropdownInput<PriceComponentOptionT, PriceComponentTypeT>
                                            selectedValue={value}
                                            options={priceComponentsOptions}
                                            onSelect={props.onChange}
                                            renderOption={(option) => (option ? <span>{option.label}</span> : value)}
                                            getOptionValue={(option) => option?.value}
                                            overlayPosition={DropdownOverlayPositionEnum.topLeft}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasChanges={!isNewRule && props.hasChanges}
                                            testSelector="target"
                                        />
                                    );
                                }}
                            </FormikField>
                            <FieldGroup isFullWidth className={cx('field-group', 'field-group--rule')}>
                                <FormikField
                                    name={FieldsEnum.impactValue}
                                    error={formik.errors[FieldsEnum.impactValue]}
                                    meta={formik.getFieldMeta(FieldsEnum.impactValue)}
                                    label={t('price-settings.rules.form.fields.impact-value.label')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <Input
                                            className={cx('combined-field--impact-value')}
                                            name={FieldsEnum.impactValue}
                                            value={formik.values[FieldsEnum.impactValue]}
                                            onChange={(value) => {
                                                props.onChange(value);
                                            }}
                                            onBlur={props.onBlur}
                                            mask={getMaskByType(formik.values[FieldsEnum.impactStrategy])}
                                            rightNode={UNIT_BY_TYPE[formik.values[FieldsEnum.impactStrategy]]}
                                            guide={false}
                                            onFocus={props.onFocus}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning}
                                            hasChanges={!isNewRule && props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                                <FormikField
                                    name={FieldsEnum.impactStrategy}
                                    error={formik.errors[FieldsEnum.impactStrategy]}
                                    meta={formik.getFieldMeta(FieldsEnum.impactStrategy)}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <DropdownInput<StrategyOptionT, RuleImpactStrategyEnum>
                                            triggerClassName={cx('combined-field--impact-strategy')}
                                            selectedValue={formik.values[FieldsEnum.impactStrategy]}
                                            options={strategyImpactOptions}
                                            onSelect={handleSelectImpactStrategy}
                                            renderOption={(option) => (option ? <span>{option.label}</span> : null)}
                                            getOptionValue={(option) => option.value}
                                            overlayPosition={DropdownOverlayPositionEnum.topLeft}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasChanges={!isNewRule && props.hasChanges}
                                            testSelector="impact"
                                        />
                                    )}
                                </FormikField>
                            </FieldGroup>
                        </FieldGroup>
                    </div>
                </div>
            </SideBarLayout>
            <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
            <FooterSideBarLayout hasPaddings>
                {isNewRule && (
                    <Button
                        isDisabled={isDisabledSubmit}
                        className={cx('button--add')}
                        type="submit"
                        theme={ButtonThemeEnum.primary}
                        testSelector="add"
                    >
                        {t('price-settings.rules.form.add-rule')}
                    </Button>
                )}
                {!isNewRule && (
                    <div className={cx('buttons')}>
                        <Button
                            theme={ButtonThemeEnum.secondary}
                            className={cx('button--cancel')}
                            type="button"
                            onClick={onGoBack ? () => onGoBack({ needCloseConfirmation: false }) : noop}
                            testSelector="cancel"
                        >
                            {t('price-settings.rules.form.cancel')}
                        </Button>
                        <Button
                            theme={ButtonThemeEnum.primary}
                            isDisabled={isDisabledSubmit}
                            className={cx('button--save')}
                            type="submit"
                            testSelector="submit"
                        >
                            {t('price-settings.rules.form.save')}
                        </Button>
                    </div>
                )}
            </FooterSideBarLayout>
        </form>
    );
};

export default PriceSettingsEditRuleSidebarContent;
