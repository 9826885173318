import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import { LongDeadheadConfirmationDataT } from './models';
import { RequestStatusT } from 'common/utils/request-status';
import { StyleGuideColorsEnum } from 'common/constants';
import { LONG_DEADHEAD_PERCENT } from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/constants';
import BigWarningIcon from 'common/icons/BigWarningIcon';

type PropsT = {
    data: LongDeadheadConfirmationDataT | null;
    requestStatus: RequestStatusT;
    onClose: () => void;
    onCancel: () => void;
    onConfirm: (data: LongDeadheadConfirmationDataT) => void;
};

const TEST_SELECTOR = 'long-deadhead-confirmation';

const LongDeadheadConfirmation: React.FC<PropsT> = React.memo((props) => {
    const { data, requestStatus, onClose, onCancel, onConfirm } = props;

    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.cancel'),
                            theme: ButtonThemeEnum.secondary,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            isDisabled: requestStatus.loading,
                            onClick: onCancel,
                        },
                        {
                            children: t('common:error-modal.actions.confirm'),
                            theme: ButtonThemeEnum.danger,
                            isLoading: requestStatus.loading,
                            isDisabled: requestStatus.loading,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            onClick: () => {
                                onConfirm(data);
                            },
                        },
                    ]}
                    icon={<BigWarningIcon baseColor={StyleGuideColorsEnum.tomatoRed} />}
                    title={t('assignment.long-deadhead-confirmation.title')}
                    message={t('assignment.long-deadhead-confirmation.description', {
                        percent: LONG_DEADHEAD_PERCENT,
                    })}
                />
            </ModalContent>
        </Modal>
    );
});

export default LongDeadheadConfirmation;
