import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './AdditionalManagementControl.scss';
import DropdownControl, {
    DropdownControlOptionT,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { useTranslation } from 'react-i18next';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import MoreIcon from 'common/icons/MoreIcon';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import { TransportOrderDetailsT } from 'common/store/transport-order-details/models';
import TeamDrive2Icon from 'common/icons/TeamDrive2Icon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import EditIcon from 'common/icons/EditIcon';
import { isNonNil } from 'common/utils';
import CloseIcon from 'common/icons/CloseIcon';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    transportOrderDetails: TransportOrderDetailsT | null | undefined;
    isShowAssignDrivers: boolean;
    isAllowAssignDrivers: boolean;
    onStartAssignDrivers: () => void;
    onUnassignedDrivers: () => void;
};

const AdditionalManagementControl: React.FC<PropsT> = React.memo((props) => {
    const {
        className,
        transportOrderDetails,
        isShowAssignDrivers,
        isAllowAssignDrivers,
        onStartAssignDrivers,
        onUnassignedDrivers,
    } = props;

    const { t } = useTranslation();

    const hasAssignedDrivers = !!transportOrderDetails?.drivers?.length;

    const options: Array<DropdownControlOptionT | SpecialOptionEnum | null> = [
        isAllowAssignDrivers && isShowAssignDrivers && !hasAssignedDrivers
            ? {
                  label: (
                      <DropdownControlOptionLabel
                          icon={<TeamDrive2Icon size={DEFAULT_ICON_SIZE} strokeColor={StyleGuideColorsEnum.slate} />}
                          label={
                              <span className={cx('option')}>
                                  {t('common:transport-order-details.actions.assign-drivers', {
                                      postProcess: 'interval',
                                      count: transportOrderDetails?.drivers?.length || 0,
                                  })}
                              </span>
                          }
                      />
                  ),
                  onSelect: () => {
                      onStartAssignDrivers();
                  },
              }
            : null,
        isAllowAssignDrivers && isShowAssignDrivers && hasAssignedDrivers
            ? {
                  label: (
                      <DropdownControlOptionLabel
                          icon={<EditIcon />}
                          label={
                              <span className={cx('option')}>
                                  {t('common:transport-order-details.actions.manage-drivers', {
                                      postProcess: 'interval',
                                      count: transportOrderDetails?.drivers?.length || 0,
                                  })}
                              </span>
                          }
                      />
                  ),
                  onSelect: () => {
                      onStartAssignDrivers();
                  },
              }
            : null,
        isAllowAssignDrivers && isShowAssignDrivers && hasAssignedDrivers
            ? {
                  label: (
                      <DropdownControlOptionLabel
                          icon={<CloseIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                          label={
                              <span className={cx('option')}>
                                  {t('common:transport-order-details.actions.clear-drivers', {
                                      postProcess: 'interval',
                                      count: transportOrderDetails?.drivers?.length || 0,
                                  })}
                              </span>
                          }
                      />
                  ),
                  onSelect: () => {
                      onUnassignedDrivers();
                  },
              }
            : null,
    ].filter(isNonNil);

    if (!options?.length) {
        return null;
    }

    return (
        <DropdownControl
            className={className}
            options={options}
            renderTrigger={(isActive, onClick) => (
                <TransparentTrigger
                    isPressed={isActive}
                    onClick={onClick}
                    leftIcon={<MoreIcon />}
                    reflectionTheme={ReflectionThemeEnum.light}
                />
            )}
            overlayPosition={DropdownOverlayPositionEnum.topRight}
        />
    );
});

export default AdditionalManagementControl;
