import { findActualTour } from 'broker-admin/store/dispatch-details/utils/find-actual-tour';
import { StopEnum } from 'common/utils/api/models';
import isNumber from 'lodash/isNumber';
import { formatLocation } from 'common/utils/formatters';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import { InitDropPointT } from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/AssignmentForm/models';

export const useInitPayloadDropPoint = (dispatchDetails: DispatchDetailsT | null): InitDropPointT | null => {
    const actualTour = findActualTour(dispatchDetails?.tours);
    const waypoints = actualTour?.waypoints || [];

    const payloadWaypoints =
        waypoints?.filter((waypoint) => {
            return waypoint.type === StopEnum.pickupDeliveryShipment;
        }) || [];
    const lastPayloadWaypoint = payloadWaypoints[payloadWaypoints.length - 1] || null;

    const waypoint = lastPayloadWaypoint || null;
    if (!waypoint) {
        return null;
    }

    const waypointAddress = waypoint?.address || null;
    if (!waypointAddress) {
        return null;
    }

    if (!isNumber(waypointAddress?.latitude) || !isNumber(waypointAddress?.longitude)) {
        return null;
    }

    return {
        location: {
            address: formatLocation(waypointAddress),
            utcOffsetMinutes: null,
            addressComponents: {
                countryCode: waypointAddress.country,
                street1: waypointAddress?.street1,
                street2: waypointAddress?.street2,
                zipCode: waypointAddress?.zipCode,
                city: waypointAddress?.city,
            },
            point: {
                lat: waypointAddress.latitude || 0,
                lng: waypointAddress.longitude || 0,
            },
        },
        index: isNumber(waypoint?.index) ? waypoint?.index : null,
    };
};
