import requestStatus from 'common/utils/request-status';
import {
    ASSIGNMENT_REQUEST_BEGIN,
    ASSIGNMENT_REQUEST_ERROR,
    ASSIGNMENT_REQUEST_SUCCESS,
    CHANGE_USER_SELECTION,
    DispatchAssigmentActionT,
    DispatchAssigmentStateT,
    FETCH_ASSET_SCHEDULE_BEGIN,
    FETCH_ASSET_SCHEDULE_ERROR,
    FETCH_ASSET_SCHEDULE_SUCCESS,
    FETCH_ASSIGN_DETAILS_REQUEST_BEGIN,
    FETCH_ASSIGN_DETAILS_REQUEST_ERROR,
    FETCH_ASSIGN_DETAILS_REQUEST_SUCCESS,
    FETCH_AVAILABLE_CARRIERS_REQUEST_BEGIN,
    FETCH_AVAILABLE_CARRIERS_REQUEST_ERROR,
    FETCH_AVAILABLE_CARRIERS_REQUEST_SUCCESS,
    FETCH_AVAILABLE_VEHICLES_REQUEST_BEGIN,
    FETCH_AVAILABLE_VEHICLES_REQUEST_ERROR,
    FETCH_AVAILABLE_VEHICLES_REQUEST_SUCCESS,
    FIND_TRAILER_REQUEST_BEGIN,
    FIND_TRAILER_REQUEST_ERROR,
    FIND_TRAILER_REQUEST_SUCCESS,
    FIND_TRUCK_REQUEST_BEGIN,
    FIND_TRUCK_REQUEST_ERROR,
    FIND_TRUCK_REQUEST_SUCCESS,
    INIT_USER_SELECTION,
    PREDICT_SELF_COST_REQUEST_BEGIN,
    PREDICT_SELF_COST_REQUEST_ERROR,
    PREDICT_SELF_COST_REQUEST_SUCCESS,
    RESET,
    ScheduleAssetT,
    SET_ASSET_SCHEDULE,
    SET_EVENT_LOAD_ROUTE,
    SET_INIT_ASSETS_INFO,
    SET_SELECTED_SCHEDULE_EVENT_ID,
    SET_SHOW_SELECTED_ASSET_SCHEDULES,
} from './types';
import { SelfCostPredictionT } from 'broker-admin/store/dispatch-assigment/models';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';
import { ALL_CARRIERS_ID } from 'common/constants';
import { getAssignmentVehicleLinks, getTrailerById, getTruckById } from 'common/utils/assignment';
import { checkIsSameQuery } from 'common/utils/pagination/utils';

const initialUserSelectionValues: DispatchAssigmentStateT['userSelection'] = {
    truckId: null,
    trailerId: null,
    excludedCountries: [],
    radiusKm: null,
    carrierId: ALL_CARRIERS_ID,
    isShowActualPlace: false,
    isShowUnavailableAssets: false,
    isShowAssetSchedule: false,
    isIgnoreCertificates: false,
    isIgnoreEmissionStandard: false,
    pointToDropTrailer: null,
    pointToDropTruck: null,
};

const initialAssetSchedule: ScheduleAssetT = {
    requestStatus: requestStatus.initial,
    events: [],
    routeById: {},
    currentEventId: null,
};

const initialState: DispatchAssigmentStateT = {
    userSelection: initialUserSelectionValues,
    trailerById: {},
    truckById: {},
    truckSuggest: {
        query: null,
        requestStatus: requestStatus.initial,
        ids: [],
    },
    trailerSuggest: {
        query: null,
        requestStatus: requestStatus.initial,
        ids: [],
    },
    availableVehicles: {
        query: null,
        requestStatus: requestStatus.initial,
        list: [],
    },
    links: {
        byTruckId: {},
        byTrailerId: {},
    },
    selfCostPrediction: {
        query: null,
        requestStatus: requestStatus.initial,
        result: null,
    },
    availableCarriers: {
        requestStatus: requestStatus.initial,
        carriers: [],
    },
    assigmentStatus: requestStatus.initial,
    currentPositionAssetById: {},
    scheduleAssetById: {},
    isShowSelectedAssetSchedules: false,
    selectedScheduleEventId: null,
    fetchAssignDetailsStatus: requestStatus.initial,
};

export default (
    state = initialState,
    action: DispatchAssigmentActionT | DestroySessionActionT,
): DispatchAssigmentStateT => {
    switch (action.type) {
        case INIT_USER_SELECTION:
        case CHANGE_USER_SELECTION: {
            const { changes } = action;
            const { userSelection: prevUserSelection } = state;

            const newUserSelection: typeof prevUserSelection = {
                ...prevUserSelection,
                ...changes,
            };

            return {
                ...state,
                userSelection: newUserSelection,
            };
        }

        case FETCH_AVAILABLE_VEHICLES_REQUEST_BEGIN: {
            const { query } = action;

            return {
                ...state,
                availableVehicles: {
                    ...state.availableVehicles,
                    requestStatus: requestStatus.loading,
                    query,
                },
            };
        }

        case FETCH_AVAILABLE_VEHICLES_REQUEST_SUCCESS: {
            const { vehicles, query } = action;

            if (!checkIsSameQuery(state.availableVehicles.query, query)) {
                return state;
            }

            const trailerById = getTrailerById(vehicles, {});

            const truckById = getTruckById(vehicles, {});

            const list = vehicles.map((vehicle) => {
                return {
                    trailerId: vehicle?.trailer?.id || null,
                    truckId: vehicle?.truck?.id || null,
                };
            });

            const links = getAssignmentVehicleLinks(vehicles);

            return {
                ...state,
                trailerById: {
                    ...state.trailerById,
                    ...trailerById,
                },
                truckById: {
                    ...state.truckById,
                    ...truckById,
                },
                links: {
                    byTruckId: {
                        ...state.links.byTruckId,
                        ...links.byTruckId,
                    },
                    byTrailerId: {
                        ...state.links.byTrailerId,
                        ...links.byTrailerId,
                    },
                },
                availableVehicles: {
                    ...state.availableVehicles,
                    list,
                    requestStatus: requestStatus.ok,
                },
            };
        }

        case FETCH_AVAILABLE_VEHICLES_REQUEST_ERROR: {
            const { error, query } = action;

            if (!checkIsSameQuery(state.availableVehicles.query, query)) {
                return state;
            }

            return {
                ...state,
                availableVehicles: {
                    ...state.availableVehicles,
                    requestStatus: requestStatus.setError(error),
                },
            };
        }

        case FETCH_AVAILABLE_CARRIERS_REQUEST_BEGIN: {
            return {
                ...state,
                availableCarriers: {
                    ...state.availableCarriers,
                    requestStatus: requestStatus.loading,
                },
            };
        }

        case FETCH_AVAILABLE_CARRIERS_REQUEST_SUCCESS: {
            const { carriers } = action;

            return {
                ...state,
                availableCarriers: {
                    ...state.availableCarriers,
                    carriers,
                    requestStatus: requestStatus.ok,
                },
            };
        }

        case FETCH_AVAILABLE_CARRIERS_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                availableCarriers: {
                    ...state.availableCarriers,
                    requestStatus: requestStatus.setError(error),
                },
            };
        }

        case FIND_TRAILER_REQUEST_BEGIN: {
            const { query } = action;

            return {
                ...state,
                trailerSuggest: {
                    ...state.trailerSuggest,
                    query,
                    requestStatus: requestStatus.loading,
                },
            };
        }

        case FIND_TRAILER_REQUEST_ERROR: {
            const { error, query } = action;

            if (!checkIsSameQuery(state.trailerSuggest.query, query)) {
                return state;
            }

            return {
                ...state,
                trailerSuggest: {
                    ...state.trailerSuggest,
                    requestStatus: requestStatus.setError(error),
                },
            };
        }

        case FIND_TRAILER_REQUEST_SUCCESS: {
            const { trailersSuggestItems, query } = action;

            if (!checkIsSameQuery(state.trailerSuggest.query, query)) {
                return state;
            }

            const ids = trailersSuggestItems.reduce((result, trailersSuggestItem) => {
                const trailerId = trailersSuggestItem.trailer?.id;
                if (trailerId) {
                    result.push(trailerId);
                }

                return result;
            }, [] as TrailerIdT[]);

            const trailerById = getTrailerById(trailersSuggestItems, state.trailerById);
            const truckById = getTruckById(trailersSuggestItems, state.truckById);
            const links = getAssignmentVehicleLinks(trailersSuggestItems);

            return {
                ...state,
                links: {
                    byTruckId: {
                        ...state.links.byTruckId,
                        ...links.byTruckId,
                    },
                    byTrailerId: {
                        ...state.links.byTrailerId,
                        ...links.byTrailerId,
                    },
                },
                trailerById: {
                    ...state.trailerById,
                    ...trailerById,
                },
                truckById: {
                    ...state.truckById,
                    ...truckById,
                },
                trailerSuggest: {
                    ...state.trailerSuggest,
                    requestStatus: requestStatus.ok,
                    ids,
                },
            };
        }

        case SET_INIT_ASSETS_INFO: {
            const { truck, trailer } = action;

            const trailerById = getTrailerById(trailer ? [trailer] : [], state.trailerById);
            const truckById = getTruckById(truck ? [truck] : [], state.truckById);

            return {
                ...state,
                trailerById: {
                    ...state.trailerById,
                    ...trailerById,
                },
                truckById: {
                    ...state.truckById,
                    ...truckById,
                },
            };
        }

        case FIND_TRUCK_REQUEST_BEGIN: {
            const { query } = action;

            return {
                ...state,
                truckSuggest: {
                    ...state.truckSuggest,
                    query,
                    requestStatus: requestStatus.loading,
                },
            };
        }

        case FIND_TRUCK_REQUEST_ERROR: {
            const { error, query } = action;

            if (!checkIsSameQuery(state.truckSuggest.query, query)) {
                return state;
            }

            return {
                ...state,
                truckSuggest: {
                    ...state.truckSuggest,
                    requestStatus: requestStatus.setError(error),
                },
            };
        }

        case FIND_TRUCK_REQUEST_SUCCESS: {
            const { trucksSuggestItems, query } = action;

            if (!checkIsSameQuery(state.truckSuggest.query, query)) {
                return state;
            }

            const ids = trucksSuggestItems.reduce((result, trucksSuggestItem) => {
                const truckId = trucksSuggestItem.truck?.id;
                if (truckId) {
                    result.push(truckId);
                }

                return result;
            }, [] as TruckIdT[]);

            const trailerById = getTrailerById(trucksSuggestItems, state.trailerById);
            const truckById = getTruckById(trucksSuggestItems, state.truckById);
            const links = getAssignmentVehicleLinks(trucksSuggestItems);

            return {
                ...state,
                links: {
                    byTruckId: {
                        ...state.links.byTruckId,
                        ...links.byTruckId,
                    },
                    byTrailerId: {
                        ...state.links.byTrailerId,
                        ...links.byTrailerId,
                    },
                },
                trailerById: {
                    ...state.trailerById,
                    ...trailerById,
                },
                truckById: {
                    ...state.truckById,
                    ...truckById,
                },
                truckSuggest: {
                    ...state.truckSuggest,
                    requestStatus: requestStatus.ok,
                    ids,
                },
            };
        }

        case PREDICT_SELF_COST_REQUEST_BEGIN: {
            const { query } = action;

            let newResult: SelfCostPredictionT | null = state.selfCostPrediction.result;
            if (newResult) {
                newResult = {
                    ...newResult,
                    truckToTrailerPolylineId: undefined,
                    trailerToPickupPolylineId: undefined,
                    payloadPolylineId: undefined,
                    dropTrailerPolylineId: undefined,
                    dropTruckPolylineId: undefined,
                };
            }

            return {
                ...state,
                selfCostPrediction: {
                    ...state.selfCostPrediction,
                    query,
                    requestStatus: requestStatus.loading,
                    result: newResult,
                },
            };
        }

        case PREDICT_SELF_COST_REQUEST_ERROR: {
            const { error, query } = action;

            if (!checkIsSameQuery(state.selfCostPrediction.query, query)) {
                return state;
            }

            return {
                ...state,
                selfCostPrediction: {
                    ...state.selfCostPrediction,
                    requestStatus: requestStatus.setError(error),
                },
            };
        }

        case PREDICT_SELF_COST_REQUEST_SUCCESS: {
            const { selfCostPrediction, query } = action;

            if (!checkIsSameQuery(state.selfCostPrediction.query, query)) {
                return state;
            }

            return {
                ...state,
                selfCostPrediction: {
                    ...state.selfCostPrediction,
                    requestStatus: requestStatus.ok,
                    result: selfCostPrediction,
                },
            };
        }

        case ASSIGNMENT_REQUEST_BEGIN: {
            return {
                ...state,
                assigmentStatus: requestStatus.loading,
            };
        }

        case ASSIGNMENT_REQUEST_SUCCESS: {
            return {
                ...state,
                assigmentStatus: requestStatus.ok,
            };
        }

        case ASSIGNMENT_REQUEST_ERROR: {
            const { error } = action;
            return {
                ...state,
                assigmentStatus: requestStatus.setError(error),
            };
        }

        case FETCH_ASSET_SCHEDULE_BEGIN: {
            const { assetId } = action;

            const assetSchedule = state.scheduleAssetById[assetId] || initialAssetSchedule;

            return {
                ...state,
                scheduleAssetById: {
                    ...state.scheduleAssetById,
                    [assetId]: {
                        ...assetSchedule,
                        requestStatus: requestStatus.loading,
                    },
                },
            };
        }

        case FETCH_ASSET_SCHEDULE_ERROR: {
            const { assetId, error } = action;

            const assetSchedule = state.scheduleAssetById[assetId] || initialAssetSchedule;

            return {
                ...state,
                scheduleAssetById: {
                    ...state.scheduleAssetById,
                    [assetId]: {
                        ...assetSchedule,
                        requestStatus: requestStatus.setError(error),
                    },
                },
            };
        }

        case FETCH_ASSET_SCHEDULE_SUCCESS: {
            const { assetId } = action;

            const assetSchedule = state.scheduleAssetById[assetId] || initialAssetSchedule;

            return {
                ...state,
                scheduleAssetById: {
                    ...state.scheduleAssetById,
                    [assetId]: {
                        ...assetSchedule,
                        requestStatus: requestStatus.ok,
                    },
                },
            };
        }

        case FETCH_ASSIGN_DETAILS_REQUEST_BEGIN: {
            return {
                ...state,
                fetchAssignDetailsStatus: requestStatus.initial,
            };
        }

        case FETCH_ASSIGN_DETAILS_REQUEST_ERROR: {
            const { error } = action;
            return {
                ...state,
                fetchAssignDetailsStatus: requestStatus.setError(error),
            };
        }

        case FETCH_ASSIGN_DETAILS_REQUEST_SUCCESS: {
            const { details } = action;
            if (!details) {
                return state;
            }

            const { truck, trailer } = details;

            const prevTrailerById = state.trailerById || {};

            const trailerById: typeof state.trailerById = trailer?.id
                ? {
                      [trailer.id]: {
                          id: trailer.id,
                          assetsToHook: prevTrailerById[trailer.id]?.assetsToHook || (truck?.id ? [truck.id] : []),
                          ...trailer,
                      },
                  }
                : {};

            const prevTruckById = state.truckById || {};

            const truckById: typeof state.truckById = truck?.id
                ? {
                      [truck.id]: {
                          id: truck.id,
                          assetsToHook: prevTruckById[truck.id]?.assetsToHook || (trailer?.id ? [trailer.id] : []),
                          ...truck,
                      },
                  }
                : {};

            return {
                ...state,
                trailerById: {
                    ...state.trailerById,
                    ...trailerById,
                },
                truckById: {
                    ...state.truckById,
                    ...truckById,
                },
                fetchAssignDetailsStatus: requestStatus.ok,
            };
        }

        case SET_ASSET_SCHEDULE: {
            const { assetId, events, currentEventId } = action;

            const assetSchedule = state.scheduleAssetById[assetId] || initialAssetSchedule;

            return {
                ...state,
                scheduleAssetById: {
                    ...state.scheduleAssetById,
                    [assetId]: {
                        ...assetSchedule,
                        currentEventId,
                        events,
                    },
                },
            };
        }

        case SET_EVENT_LOAD_ROUTE: {
            const { assetId, scheduleEventId, polylines } = action;

            const assetSchedule = state.scheduleAssetById[assetId] || initialAssetSchedule;

            return {
                ...state,
                scheduleAssetById: {
                    ...state.scheduleAssetById,
                    [assetId]: {
                        ...assetSchedule,
                        routeById: {
                            ...assetSchedule.routeById,
                            [scheduleEventId]: polylines,
                        },
                    },
                },
            };
        }

        case SET_SHOW_SELECTED_ASSET_SCHEDULES: {
            const { isShowSelectedAssetSchedules } = action;
            return {
                ...state,
                isShowSelectedAssetSchedules,
                selectedScheduleEventId: isShowSelectedAssetSchedules ? state.selectedScheduleEventId : null,
            };
        }

        case SET_SELECTED_SCHEDULE_EVENT_ID: {
            const { selectedScheduleEventId } = action;

            return {
                ...state,
                selectedScheduleEventId,
            };
        }

        case RESET: {
            return {
                ...initialState,
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default:
            return state;
    }
};
