import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectScheduleAssetById,
    selectSelectedScheduleEventId,
    selectSelfCostPrediction,
    selectUserSelection,
} from 'broker-admin/store/dispatch-assigment/selectors';
import TruckIcon, { getTruckIconProps } from 'common/icons/TruckIcon';
import TrailerIcon, { getTrailerIconProps } from 'common/icons/TrailerIcon';
import TimelineSidebar from 'common/components/Timeline/TimelineSidebar/TimelineSidebar';
import { TimeWindowT, TimeWindowThemeEnum } from 'common/components/Timeline/Timeline';
import { VehicleScheduleEventT } from 'common/store/vehicle-schedules/models';
import { parseDateString } from 'common/utils/time';
import { VehicleScheduleEventTypeEnum } from 'common/utils/api/models';
import CargoUnitIcon, { getCargoUnitIconProps } from 'common/icons/CargoUnitIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import { TFunction } from 'i18next';
import { ScheduleEventIdT } from 'broker-admin/store/dispatch-assigment/models';
import {
    setSelectedScheduleEventId,
    setShowSelectedAssetSchedule,
} from 'broker-admin/store/dispatch-assigment/actions';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import { CURRENT_ASSIGNMENT_EVENT_ID } from '../constants';
import LockIcon from 'common/icons/LockIcon';
import isNumber from 'lodash/isNumber';
import TimeZoneContext from '../contexts/timezone-context';
import { findActualTransportOrders } from 'broker-admin/store/dispatch-details/utils/find-actual-transport-order';
import { findActualTour } from 'broker-admin/store/dispatch-details/utils/find-actual-tour';

const convertScheduleEvent = (
    type: 'truck' | 'trailer',
    dispatchDetails: DispatchDetailsT | null,
    startTime: string | null,
    finishTime: string | null,
): VehicleScheduleEventT | null => {
    if (!dispatchDetails || !startTime || !finishTime) {
        return null;
    }

    const actualTour = findActualTour(dispatchDetails?.tours);
    const { carrierTransportOrder } = findActualTransportOrders(actualTour?.transportationOrders);

    if (type === 'truck') {
        return {
            truckDateFrom: startTime || undefined,
            truckDateTo: finishTime || undefined,
            eventType: VehicleScheduleEventTypeEnum.shipment,
            id: CURRENT_ASSIGNMENT_EVENT_ID,
            transportationOrderNumber: carrierTransportOrder?.number,
            hasAccessToDispatch: false,
            hasWarning: false,
        };
    }

    if (type === 'trailer') {
        return {
            trailerDateFrom: startTime || undefined,
            trailerDateTo: finishTime || undefined,
            eventType: VehicleScheduleEventTypeEnum.shipment,
            id: CURRENT_ASSIGNMENT_EVENT_ID,
            transportationOrderNumber: carrierTransportOrder?.number,
            hasAccessToDispatch: false,
            hasWarning: false,
        };
    }

    return null;
};

const TIMELINE_ICON_SIZE = 16;

type GetTimeWindowsParamsT = {
    t: TFunction;
    type: 'truck' | 'trailer';
    events: Array<VehicleScheduleEventT>;
    currentEventId: ScheduleEventIdT | null;
};

const getTimeWindows = ({ t, type, events, currentEventId }: GetTimeWindowsParamsT): Array<TimeWindowT<any>> => {
    const now = Date.now();

    return events.reduce<Array<TimeWindowT<null>>>((result, scheduleEvent: VehicleScheduleEventT) => {
        const dateFrom = type === 'truck' ? scheduleEvent.truckDateFrom : scheduleEvent.trailerDateFrom;
        const start = parseDateString(dateFrom);

        const dateTo = type === 'truck' ? scheduleEvent.truckDateTo : scheduleEvent.trailerDateTo;
        const end = parseDateString(dateTo);

        if (start && end) {
            let label = '';
            let theme = TimeWindowThemeEnum.accent;
            let icon: React.ReactNode = null;

            if (scheduleEvent.eventType === VehicleScheduleEventTypeEnum.serviceMaintenance) {
                label = t('common:vehicle-schedule.event-type.unavailable');
                theme = TimeWindowThemeEnum.unavailable;
                icon = <LockIcon size={TIMELINE_ICON_SIZE} fillColor={StyleGuideColorsEnum.white} />;
            }

            if (scheduleEvent.eventType === VehicleScheduleEventTypeEnum.shipment) {
                label = t('common:vehicle-schedule.event-type.transportation-order', {
                    number: scheduleEvent.transportationOrderNumber || '',
                });

                if (scheduleEvent.id === CURRENT_ASSIGNMENT_EVENT_ID) {
                    theme = TimeWindowThemeEnum.accent;
                    icon = (
                        <CargoUnitIcon size={TIMELINE_ICON_SIZE} {...getCargoUnitIconProps('schedule-event-accent')} />
                    );
                } else if (scheduleEvent.id === currentEventId) {
                    theme = TimeWindowThemeEnum.inProgress;
                    icon = (
                        <CargoUnitIcon size={TIMELINE_ICON_SIZE} {...getCargoUnitIconProps('schedule-event-regular')} />
                    );
                } else if (start <= now && end <= now) {
                    theme = TimeWindowThemeEnum.past;
                    icon = (
                        <CargoUnitIcon size={TIMELINE_ICON_SIZE} {...getCargoUnitIconProps('schedule-event-past')} />
                    );
                } else if (now <= start && now <= end) {
                    theme = TimeWindowThemeEnum.future;
                    icon = (
                        <CargoUnitIcon size={TIMELINE_ICON_SIZE} {...getCargoUnitIconProps('schedule-event-future')} />
                    );
                }
            }

            result.push({
                id: scheduleEvent.id || '',
                data: null,
                start,
                end,
                label,
                icon,
                theme,
                isTransparent: false,
            });
        }

        return result;
    }, []);
};

type PropsT = {
    dispatchDetails: DispatchDetailsT | null;
};

const AssignmentAssetSchedules: React.FC<PropsT> = React.memo((props) => {
    const { dispatchDetails } = props;

    const timeZoneContext = useContext(TimeZoneContext);
    const utcOffsetMin = timeZoneContext?.value?.utcOffsetMin;

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const selfCostPrediction = useSelector(selectSelfCostPrediction);

    const selectedScheduleEventId = useSelector(selectSelectedScheduleEventId);

    const userSelection = useSelector(selectUserSelection);
    const scheduleAssetById = useSelector(selectScheduleAssetById);

    const selectedTrailerId = userSelection?.trailerId || null;
    const trailerSchedule = scheduleAssetById[selectedTrailerId as TrailerIdT] || null;
    const selectedTruckId = userSelection?.truckId || null;
    const truckSchedule = scheduleAssetById[selectedTruckId as TruckIdT] || null;

    const levels = React.useMemo(() => {
        const truckScheduleEvents: Array<VehicleScheduleEventT> = [...(truckSchedule?.events || [])];
        const trailerScheduleEvents: Array<VehicleScheduleEventT> = [...(trailerSchedule?.events || [])];

        const truckStartTime = selfCostPrediction?.truckStartTime || null;
        const dropTruckDateTime = selfCostPrediction?.dropTruckDateTime || null;
        const scheduleTruckEvent = convertScheduleEvent('truck', dispatchDetails, truckStartTime, dropTruckDateTime);
        if (scheduleTruckEvent && selectedTruckId) {
            truckScheduleEvents.push(scheduleTruckEvent);
        }

        const trailerStartTime = selfCostPrediction?.trailerStartTime || null;
        const dropTrailerDateTime = selfCostPrediction?.dropTrailerDateTime || null;
        const scheduleTrailerEvent = convertScheduleEvent(
            'trailer',
            dispatchDetails,
            trailerStartTime,
            dropTrailerDateTime,
        );
        if (scheduleTrailerEvent && selectedTrailerId) {
            trailerScheduleEvents.push(scheduleTrailerEvent);
        }

        return [
            {
                timeWindows: getTimeWindows({
                    t,
                    type: 'truck',
                    events: truckScheduleEvents,
                    currentEventId: truckSchedule?.currentEventId || null,
                }),
                icon: <TruckIcon {...getTruckIconProps('assignment-normal')} />,
            },
            {
                timeWindows: getTimeWindows({
                    t,
                    type: 'trailer',
                    events: trailerScheduleEvents,
                    currentEventId: trailerSchedule?.currentEventId || null,
                }),
                icon: <TrailerIcon {...getTrailerIconProps('assignment-normal')} />,
            },
        ];
    }, [selectedTruckId, truckSchedule, selectedTrailerId, trailerSchedule, dispatchDetails, selfCostPrediction, t]);

    const isLoading = (!!selectedTrailerId && !trailerSchedule) || (!!selectedTruckId && !truckSchedule);

    const handleClose = () => {
        dispatch(setShowSelectedAssetSchedule(false));
    };

    const handleEventFocus = (scheduleEventId: ScheduleEventIdT): void => {
        dispatch(setSelectedScheduleEventId(scheduleEventId));
    };

    const handleEventBlur = (): void => {
        dispatch(setSelectedScheduleEventId(null));
    };

    return (
        <TimelineSidebar
            isLoading={isLoading}
            onClose={handleClose}
            levels={levels}
            utcOffset={isNumber(utcOffsetMin) ? utcOffsetMin : null}
            onTimeWindowBlur={handleEventBlur}
            onTimeWindowFocus={handleEventFocus}
            selectedTimeWindowId={selectedScheduleEventId}
        />
    );
});

export default AssignmentAssetSchedules;
