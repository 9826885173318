import classNames from 'classnames/bind';
import styles from './MapTooltipMenu.scss';
import * as React from 'react';
import OutsideClickHandler from 'design-system/components/OutsideClickHandler/OutsideClickHandler';

const cx = classNames.bind(styles);

export type MapTooltipMenuItemT = {
    label: React.ReactNode;
    onSelect: () => void;
    testSelector?: string;
};

type PropsT = {
    className?: string;
    onOutsideClick: () => void;
    items: Array<MapTooltipMenuItemT>;
};

const MapTooltipMenu: React.FC<PropsT> = React.memo((props) => {
    const { className, onOutsideClick, items } = props;

    return (
        <OutsideClickHandler onOutsideClick={onOutsideClick}>
            <div
                className={cx('overlay', className)}
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                }}
            >
                {items.map((item, index) => (
                    <div
                        key={index}
                        className={cx('option')}
                        data-testid={`option-${item.testSelector}`}
                        onClick={item.onSelect}
                    >
                        {item.label}
                    </div>
                ))}
            </div>
        </OutsideClickHandler>
    );
});

export default MapTooltipMenu;
