import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import { isNonNil } from 'common/utils';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import RouteIcon from 'common/icons/RouteIcon';
import { ApiDistancePartT } from 'broker-admin/store/dispatch-assigment/models';
import isNumber from 'lodash/isNumber';
import { convertToKm } from 'common/utils/distance';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import AsyncCountryFormatter from 'design-system/components/InfoTable/formatters/AsyncCountryFormatter/AsyncCountryFormatter';

type PropsT = {
    className?: string;
    totalDistance: number | null;
    deadheadDistance: ApiDistancePartT | null;
    payloadDistance: ApiDistancePartT | null;
    isNotActual: boolean;
};

const MileageInfoTable: React.FC<PropsT> = React.memo((props) => {
    const { className, totalDistance, deadheadDistance, payloadDistance } = props;

    const { t } = useTranslation();

    const getDistancePartRows = (distancePart: ApiDistancePartT | null | undefined): Array<InfoTableRowT | null> => {
        if (!distancePart) {
            return [];
        }

        return Object.values(distancePart?.countries).map(({ country: countryCode, distance }) => {
            return {
                icon: <FlagIcon countryCode={countryCode} />,
                name: !countryCode ? (
                    t('common:tolls.unspecified')
                ) : (
                    <AsyncCountryFormatter countryCode={countryCode} />
                ),
                isBoldValue: true,
                value: <UnitTypeCount type={UnitTypeEnum.kilometersAbbreviation} count={convertToKm(distance)} />,
            };
        });
    };

    const rows: Array<InfoTableRowT | null> = [
        isNumber(totalDistance)
            ? {
                  icon: <RouteIcon fillColor={StyleGuideColorsEnum.gray} />,
                  isInitOpenRows: true,
                  name: t('assignment.receipt.total-mileage'),
                  value: (
                      <UnitTypeCount type={UnitTypeEnum.kilometersAbbreviation} count={convertToKm(totalDistance)} />
                  ),
                  isBoldValue: true,
                  rows: [
                      isNumber(payloadDistance?.total)
                          ? {
                                icon: null,

                                name: t('assignment.receipt.payload-mileage'),
                                value: (
                                    <UnitTypeCount
                                        type={UnitTypeEnum.kilometersAbbreviation}
                                        count={convertToKm(payloadDistance?.total)}
                                    />
                                ),
                                isBoldValue: true,
                                rows: getDistancePartRows(payloadDistance),
                            }
                          : null,
                      isNumber(deadheadDistance?.total)
                          ? {
                                icon: null,
                                name: t('assignment.receipt.deadhead-mileage'),
                                value: (
                                    <UnitTypeCount
                                        type={UnitTypeEnum.kilometersAbbreviation}
                                        count={convertToKm(deadheadDistance?.total)}
                                    />
                                ),
                                isBoldValue: true,
                                rows: getDistancePartRows(deadheadDistance),
                            }
                          : null,
                  ],
              }
            : null,
    ].filter(isNonNil);

    return <InfoTable className={className} shouldRenderIcons rows={rows} />;
});

export default MileageInfoTable;
