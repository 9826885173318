import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { AssetAssignmentInfoT, BrokerAssetsSearchRequestInvalidReasonT } from 'common/utils/assignment';

const reasonI18nMap: Record<BrokerAssetsSearchRequestInvalidReasonT, string> = {
    OTHER: 'assignment.validation.reasons.OTHER',
    CARRIER_NOT_IN_LIST: 'assignment.validation.reasons.CARRIER_NOT_IN_LIST',
    CARRIER_DOESNT_HAVE_REQUIRED_CERTIFICATES:
        'assignment.validation.reasons.CARRIER_DOESNT_HAVE_REQUIRED_CERTIFICATES',
    INCAPABLE_EMISSION_STANDARD: 'assignment.validation.reasons.INCAPABLE_EMISSION_STANDARD',
    CANT_BE_ON_TIME_FOR_NEXT_TOUR: 'assignment.validation.reasons.CANT_BE_ON_TIME_FOR_NEXT_TOUR',
    NO_AVAILABLE_HOOKS: 'assignment.validation.reasons.NO_AVAILABLE_HOOKS',
    DIFFERENT_CARRIERS: 'assignment.validation.reasons.DIFFERENT_CARRIERS',
    INCAPABLE_TRAILER_TYPE: 'assignment.validation.reasons.INCAPABLE_TRAILER_TYPE',
    ASSET_IS_NOT_CONTRACTED: 'assignment.validation.reasons.ASSET_IS_NOT_CONTRACTED',
    ASSET_CONTRACTED_INACTIVE: 'assignment.validation.reasons.ASSET_CONTRACTED_INACTIVE',
};

type MetaT = {
    trailerById: Record<TrailerIdT, AssetAssignmentInfoT>;
    truckById: Record<TruckIdT, AssetAssignmentInfoT>;
};

const validate = (t: TFunction, values: FormValuesT, meta: MetaT): FormErrorsT => {
    const { trailerById, truckById } = meta;

    const errors: FormErrorsT = {};

    const selectedTrailerId = values[FieldsEnum.trailerId];
    const selectedTrailer = selectedTrailerId ? trailerById[selectedTrailerId] : null;
    if (selectedTrailer?.invalidReason) {
        errors[FieldsEnum.trailerId] = t(reasonI18nMap[selectedTrailer.invalidReason]);
    }

    const selectedTruckId = values[FieldsEnum.truckId];
    const selectedTruck = selectedTruckId ? truckById[selectedTruckId] : null;
    if (selectedTruck?.invalidReason) {
        errors[FieldsEnum.truckId] = t(reasonI18nMap[selectedTruck.invalidReason]);
    }

    if (!selectedTruck && !selectedTrailer) {
        errors[FieldsEnum.truckId] = t('assignment.validation.required-asset');
        errors[FieldsEnum.trailerId] = t('assignment.validation.required-asset');
    }

    return errors;
};

export default validate;
